import React, { useEffect, useState } from "react";
import image from "../image";
import './residence.scss';
import SubMenu from "../../menu/menu";
import { Swiper, SwiperSlide } from "swiper/react";
import { residenceContent } from "../../../../copywriting";
import null4 from '../images/4.jpeg';
const Residence = ({ lang, onTabChange }) => {
  let [height, setHeight] = useState(0);
  useEffect(() => {
    window.addEventListener('resize', getHeight.bind(this)); //监听窗口大小改变
    setTimeout(() => {
      getHeight()
    }, 100)
  }, []);
  const getHeight = () => {
    let dom = document.querySelector(".residence-context");
    if (!dom) return
    let heigth = dom.clientHeight;
    setHeight(heigth)
  }
  const toNextPage = () => {
    onTabChange('about-banner3');
  }
  const toAdress = () => {
    // onTabChange('address');
    window.location.href = '/contact';
  }
  return (
    <>
        <div className="residence-container scroll-item" id='residence'>
          <div className="residence-main">
            <div className="residence-context" >
              <div className="context">
                <div className="context-header">
                  <span style={{visibility: "hidden"}}>jing an kerry centre </span>
                  <span className="context-header-connecting-line"
                    style={{visibility: "hidden"}}
                  ></span>
                  <span style={{visibility: "hidden"}}> centre · core · creativity · connection</span>
                </div>
                <div className="describe">
                  <div className="context-left">
                    <div className="title">
                      <img src={residenceContent.jingAnResidence[lang]} alt="" />
                    </div>
                  </div>
                  <div className="context-right">
                    <img src={null4} className="nullimg" />
                    <div className="text-one">{residenceContent.description1[lang]}</div>
                    <div className="text-two">
                      {residenceContent.description2[lang]}
                    </div>

                    <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                        <div className="page-btn" onClick={toAdress}>
                          {residenceContent.buttonText[lang]} <img src={image.path} />
                        </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="arrow-down" onClick={toNextPage}>
                <img src={image.arrow_down} alt="" />
              </div>
            </div>
          </div>
        </div>

    </>
  );
};

export default Residence;
