import React, { useEffect, useState } from "react";
import image from "../image";
import './hotel.scss';
import { hotelContent } from "../../../../copywriting";
import 'animate.css';
import ReactCSSTransitionGroup from "react-addons-css-transition-group";
import axios from 'axios';

const Hotel = ({ lang }) => {
  let [height, setHeight] = useState(0);
  const [hotelImgs, setHotelImgs] = useState([]);

  useEffect(() => {
    window.addEventListener('resize', getHeight.bind(this)); //监听窗口大小改变
    setTimeout(() => {
      getHeight()
    }, 100);

    getHotelImgs();
  }, []);

  function getHotelImgs() {
    axios.get(`https://qa-jakc-www.kerryplus.com/jeecg-boot/imgSetting/imgSetting/list?useType=links`).then( res => {
      // console.log(res.data)
      if (res.data.success) {
        let data = res.data.result ? res.data.result.records : [];
        setHotelImgs(data);
      }
    }).catch( err => {
      // console.log(err)
    })
  }

  const getHeight = () => {
    let dom = document.querySelector(".hotel-context");
    if (!dom) return
    let heigth = dom.clientHeight;
    setHeight(heigth)
  }

  const hotelMore = () => {
    if (sessionStorage.getItem('lang')) {
      let lang = sessionStorage.getItem('lang');
      if (lang == 'en') {
        window.location.href = `https://www.shangri-la.com/en/shanghai/jinganshangrila/`
      } else {
        window.location.href = `https://www.shangri-la.com/cn/shanghai/jinganshangrila/`
      }
    }
  }

  return (
    <>
   
        <div className="hotel-container scroll-item" id='hotel'>
          <div className="hotel-main">
            <div className="hotel-context">
              <div className="context">
                <div className="context-header">
                  <span style={{visibility: "hidden"}}>jing an kerry centre </span>
                  <span className="context-header-connecting-line"
                    style={{visibility: "hidden"}}
                  ></span>
                  <span style={{visibility: "hidden"}}> centre · core · creativity · connection</span>
                </div>
                <div className="describe">
                  <div className="context-left">
                    <div className="title">
                    <img src={hotelContent.shangriLaPng[lang]} alt="" />
                    </div>
                  </div>
                  <div className="page-btn"
                    onClick={hotelMore}
                  >
                    {hotelContent.buttonText[lang]} <img src={image.path} />
                  </div>
                </div>
                <div className="picture">
                  <img src={hotelImgs.length > 0 ? hotelImgs[0].imgUrl : image.hotel_1} alt="" className="picture-img" />
                </div>
                
              </div>
            </div>
          </div>
        </div>

    </>
  );
};

export default Hotel;
