import React, { useEffect, useState } from "react";
import './privacy.scss';
import axios from 'axios';

const Privacy = () => {
  const [htmlContent, sethtmlContent] = useState(null);
  const [lang, setLang] = useState('zh');

  useEffect(() => {
    if (sessionStorage.getItem('lang')) {
      setLang(sessionStorage.getItem('lang'));
    }

    getData();
  }, [])

  const getData = () => {
    axios.get(`https://qa-jakc-www.kerryplus.com/jeecg-boot/jakcText/jakcText/list?name=隐私`).then( res => {
      // console.log(res.data)
      if (res.data.success) {
        if (lang == 'zh') {
          sethtmlContent(res.data.result.records[0].cnContent);
        } else if (lang == 'en') {
          sethtmlContent(res.data.result.records[0].enContent);
        }
      }
    }).catch( err => {
      // console.log(err)
      // setActive(false);
    })
  }

  function createMarkup(html) {
    return { __html: html };
  }
   
  function RichTextRenderer({ htmlContent }) {
    return <div dangerouslySetInnerHTML={createMarkup(htmlContent)} />;
  }

  return (
    <>
      <div className="line"></div>
      <div className='p-content'>
        {htmlContent ? <RichTextRenderer htmlContent={htmlContent} /> : ''}
      </div>
    </>
  );
};

export default Privacy;
