import React, { useEffect, useState } from "react";
import image from "../image";
import SubMenu from "../../menu/menu";
import { Swiper, SwiperSlide } from "swiper/react";
import "./office.scss";
import { officeContent } from "../../../../copywriting";
import null3 from '../images/3-1.jpg';
const Office = ({ lang, onTabChange }) => {

  const toAdress = () => {
    // onTabChange('address');
    window.location.href = '/contact';
  }
  const toNextPage = () => {
    onTabChange('about-banner2');
  }
  return (
    <>
      <div className="office-container scroll-item" id='office'>
        <div className="office-main">
          <div className="office-context">
            <div className="context">
              <div className="context-header">
                <span style={{visibility: "hidden"}}>jing an kerry centre </span>
                <span className="context-header-connecting-line"
                  style={{visibility: "hidden"}}
                ></span>
                <span style={{visibility: "hidden"}}> centre · core · creativity · connection</span>
              </div>
              <div className="describe">
                <div className="context-left">
                  <div className="title">
                    <img src={officeContent.jingAnOffice[lang]} alt=""/>
                  </div>
                </div>
                <div className="context-right office-right">
                  <img src={null3} className="nullimg" />
                  <div className="text-one">{officeContent.description1[lang]}</div>
                  <div className="text-two">{officeContent.description2[lang]} </div>

                  <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                    <div className="page-btn" onClick={toAdress}>
                      {officeContent.buttonText[lang]}<img src={image.path} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            
            <div className="arrow-down" onClick={toNextPage}>
              <img src={image.arrow_down} alt="" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Office;
