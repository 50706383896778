import React, { useEffect, useState } from "react";
import image from "../about/image";
import './index.scss';
import MenuDialog from "../menu/menuDialog"
const Header = ({ currentTab, subMenuShow, onTabChange, getLang }) => {
  const [language, setLanguage] = useState('zh');
  const [menuLayerShow, setMenuLayerShow] = useState(true);
  useEffect(() => {
    let lang = sessionStorage.getItem(('lang'))
    if (lang) {
      setLanguage(lang)
    }
  })
  const onLanguageChange = (lang) => {
    setLanguage(lang);
    getLang(lang);
    sessionStorage.setItem('lang', lang);
  }
  return (
    <>
      <div className="header-logo1" id='top-logo'>
        <img src={image.logo1} alt="" className="logo1" />
        <span className="typeface">
          <span className={language === 'zh' ? 'active' : ''} onClick={() => onLanguageChange('zh')}>简</span>
          <span style={{ margin: '0 10px' }}>|</span>
          <span className={language === 'tzh' ? 'active' : ''} onClick={() => onLanguageChange('tzh')}>繁</span>
          <span style={{ margin: '0 10px' }}>|</span>
          <span className={language === 'en' ? 'active' : ''} onClick={() => onLanguageChange('en')}>EN</span>
        </span>
        <MenuDialog
          onClose={() =>{ setMenuLayerShow(false)}}
          currentTab={currentTab}
          language={language}
          onLanguageChange={onLanguageChange}
          subMenuShow={subMenuShow}
          onSubTabChange={onTabChange}
        />
        {/* <img src={image.mobile_menu} alt="" className="mobile-menu-icon" onClick={() => setMenuLayerShow(true)} /> */}
      </div>
    </>
  );
};

export default Header;
