import React, { useEffect, useState } from "react";
import image from "../about/image";
import './index.scss';
import TextCon from "../../../textContent";

const Footer = ({style, lang}) => {
  // console.log(TextCon, lang)
  return (
    <>
      <div className="foot-bar" style={style?style:{}}>
        <span className="text-one">
          {TextCon['footerAdd'][lang]} 
          <span className="footer-left">
            <span style={{margin:'0 20px'}}>|</span> 
            <span 
              style={{cursor:'pointer'}}
              onClick={() => {
              window.location.href = '/privacy'
            }}>{TextCon['footerPri'][lang]}</span>
          </span>
        </span>
        <span className="text-one footer-right">{TextCon['footerR1'][lang]} <img src={image.bottom_icon} alt="" /> <span>{TextCon['footerR2'][lang]} </span></span>
      </div>
    </>
  );
};

export default Footer;
