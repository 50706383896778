import brand_tzh from "../pages/component/about/images/brands-tzh.png";
import brand_zh from "../pages/component/about/images/brands-zh.png";
import brand_en from "../pages/component/about/images/brands-en.png";
import jack from "../pages/component/about/images/jakc.png";
import jngAn_office_zh from "../pages/component/about/images/jngAn-office-zh.png";
import jngAn_office_tzh from "../pages/component/about/images/jngAn-office-tzh.png";
import jngAn_office_en from "../pages/component/about/images/jngAn-office-en.png";
import jingAn_center_en from '../pages/component/about/images/jingAn-en.png';
import jingAn_center_zh from '../pages/component/about/images/jingAn-zh.png';
import jingAn_center_tzh from '../pages/component/about/images/jingAn-tzh.png';
import jing_an_residence_zh from "../pages/component/about/images/jing-an-residence-zh.png";
import jing_an_residence_tzh from "../pages/component/about/images/jing-an-residence-tzh.png";
import jing_an_residence_en from "../pages/component/about/images/jing-an-residence-en.png";
import ask_us_en from "../pages/component/about/images/ask-us-en.png";
import ask_us_tzh from "../pages/component/about/images/ask-us-tzh.png";
import ask_us_zh from "../pages/component/about/images/ask-us-zh.png";
import jing_an_hotel_en from "../pages/component/about/images/jing-an-hotel-en.png";
import jing_an_hotel_zh from "../pages/component/about/images/jing-an-hotel-zh.png";
import jing_an_hotel_tzh from "../pages/component/about/images/jing-an-hotel-tzh.png";

import meet_here_en from "../pages/component/about/images/meet-here-en.png";
import meet_here_tzh from "../pages/component/about/images/meet-here-tzh.png";
import meet_here_zh from "../pages/component/about/images/meet-here-zh.png";
export const menuContent = {
  home: {
    zh: '主页',
    tzh: '主頁',
    en: 'home',
  },
  about: {
    zh: '关于我们',
    tzh: '關於我們',
    en: 'about',
  },
  contact: {
    zh: '联系我们',
    tzh: '聯繫我們',
    en: 'contact',
  },
  jakcMall: {
    zh: 'JAKC 商场',
    tzh: 'JAKC 商場',
    en: 'JAKC mall',
  },
  office: {
    zh: '办公',
    tzh: '辦公',
    en: 'office',
  },
  residence: {
    zh: '公寓',
    tzh: '公寓',
    en: 'residence',
  },
  hotel: {
    zh: '酒店',
    tzh: '酒店',
    en: 'hotel',
  },
  address: {
    zh: '位置',
    tzh: '位置',
    en: 'location',
  },
  language:{
    zh: '语言',
    tzh: '語言',
    en: 'language',
  }

}
export const aboutContent = {
  meet: {
    zh: '关于',
    tzh: '關於',
    en: 'meet',
  },
  me: {
    zh: '',
    tzh: '',
    en: '',
  },
  at: {
    zh: '',
    tzh: '',
    en: '',
  },
  jingAnCenter: {
    zh: jingAn_center_zh,
    tzh: jingAn_center_tzh,
    en: jingAn_center_en,
  },
  jingAn: {
    zh: '静安',
    tzh: '靜安',
    en: 'Jing An',
  },
  kerry: {
    zh: '嘉里中心',
    tzh: '嘉里中心',
    en: 'Kerry',
  },
  centre: {
    zh: '',
    tzh: '',
    en: 'Centre',
  },
  description1: {
    zh: '作为嘉里建设集团最具标志性的综合用途发展项目之一，静安嘉里中心坐落于国际级消费集聚区南京西路商圈，总建筑面积456,000平方米，是集高端时尚零售、超甲级写字楼、五星级酒店及豪华服务式公寓为一体的大型商业综合体。',
    tzh: '作為嘉里建設集團最具標誌性的綜合用途發展項目之一，靜安嘉里中心坐落於國際級消費集聚區南京西路商圈，總建築面積456,000平方米，是集高端時尚零售、超甲級寫字樓、五星級酒店及豪華服務式公寓為一體的大型商業綜合體。',
    en: 'JingAn Kerry Centre is one of Kerry Properties’ most representative mixed-use developments. Located in the heart of West Nanjing Road (one of the most luxurious and flourishing commercial avenues in Shanghai), this 456,000 sqm GFA complex comprises 94,000 sqm of retail space, three Grade-A office towers, one serviced apartment tower, and one luxury hotel.',
  },
  description2: {
    zh: '静安嘉里中心自开业以来持续为消费者提供多元城市生活体验，未来也将继续引领精致高端生活方式，提振城市文化与经济活力。',
    tzh: '靜安嘉里中心自開業以來持續為消費者提供多元城市生活體驗，未來也將繼續引領精致高端生活方式，提振城市文化與經濟活力。',
    en: 'The icon among icons in Shanghai and even China, JingAn Kerry Centre takes up the leading role of luxury and exquisite lifestyle, gives a much-needed boost to the city culture and economy.',
  },
  buttonText: {
    zh: '联系我们',
    tzh: '聯繫我們',
    en: 'reach out',
  }
}
export const jakcMallContent = {
  jack: {
    zh: jack,
    tzh: jack,
    en: '',
  },
  meetOurBrand: {
    zh: brand_zh,
    tzh: brand_tzh,
    en: brand_en,
  },
  description1: {
    zh: '作为上海乃至全国的时尚地标，静安嘉里中心的商业空间JAKC云集了近200家包含高端时尚、运动风尚、餐饮美馔、休闲娱乐等多元业态的国内外优质品牌，建筑面积94,000平方米。自2013年开业以来，JAKC每年吸引客流超2,200万人次，向消费者提供独树一帜的品牌选择和丰富精彩的互动体验。',
    tzh: '作爲上海廼至全國的時尙地標，靜安嘉里中心的商業空間JAKC雲集了近200傢包含高端時尙、運働風尙、餐飲美饌、休閑娛樂等多元業態的國內外優質品牌，建築面積94,000平方米。自2013年開業以來，JAKC每年吸引客流超2,200萬人次，為消費者提供獨樹一幟的品牌選擇和豐富精綵的互働體騐。',
    en: 'JAKC, the retail space of JingAn Kerry Centre, houses 200+ top-ranking brands of all categories, from luxury and fashion, premium sports and lifestyle, beauty and accessories, commercial and casual dining, high-end supermarket to cinema. JAKC has been attracting a traffic flow of more than 22millions per year, persistently introduces the most prestigious brands and intriguing social experience to the city.'
  },
}
export const officeContent = {
  greatMindsMeet: {
    zh: '',
    tzh: '',
    en: 'great minds meet',
  },
  jingAnOffice: {
    zh: jngAn_office_zh,
    tzh: jngAn_office_tzh,
    en: jngAn_office_en,
  },
  description1: {
    zh: `静安嘉里中心办公楼包含3幢超甲级标准塔楼，总建筑面积156,000平方米，身处上海中心首屈一指的核心商务区位，具备国际领先的楼宇硬件设施、卓越的物业服务水准和生态健康的办公环境，吸引了包括世界500强在内的众多国内外知名企业和机构选址入驻，致力于为办公楼租户提供高效舒适的工作环境，打造兼具品质感和创造力的商务氛围。`,
    tzh: `靜安嘉里中心辦公樓包含3幢超甲級標準塔樓，縂建築面積156,000平方米，身處上海中心首屈一指的覈心商務區位，具俻國際領先的樓宇硬件設施、卓越的物業服務水準和生態健康的辦公環境，吸引了包括世界500強在內的衆多國內外知名企業和機搆選址入駐，緻力於爲辦公樓租戶提供高傚舒適的工作環境，打造兼具品質感和剏造力的商務氛圍。`,
    en: `Occupying a GFA of 156,000 sqm in the business heart of the city, JingAn Kerry Centre's office towers are accommodated with world-class facilities and services, together with eco-friendly working environment of no parallel. Long-term lettings to companies listed on Fortune 500 are proof of its popularity among top-tier business occupiers. Aiming to activate and enliven the business vicinity, this is a one-of-a-kind workplace in what will blend productive work and nourishing city life.`,
  },
  description2: {
    zh: `其办公塔楼由国际知名建筑设计团队，以超甲级标准设计建造，以综合环保、维护保养等多重角度精心把控，已获美国绿色建筑委员会颁发的LEED能源与环境设计先锋奖认证金级证书。`,
    tzh: `其辦公塔樓由國際知名建築設計糰隊，以超甲級標準設計建造，以綜合環保、維護保養等多重角度精心把控，已穫美國緑色建築委員會頒髮的LEED能源與環境設計先鋒奬認証金級証書。`,
    en: `JingAn Kerry Centre's office towers have been designed to Premium Grade A standard, guided by an international team of consultants and engineers. This includes environmentally conscious construction, maintenance and achievement of LEED Gold Pre-certification from the U.S. Green Building Council.`,
  },
  buttonText: {
    zh: '联系我们',
    tzh: '聯繫我們',
    en: 'reach out',
  }
}
export const residenceContent = {
  meet: {
    zh: '关于',
    tzh: '關於',
    en: 'meet',
  },
  me: {
    zh: '',
    tzh: '',
    en: 'me',
  },
  at: {
    zh: '',
    tzh: '',
    en: 'at',
  },
  jingAnResidence: {
    zh: jing_an_residence_zh,
    tzh: jing_an_residence_tzh,
    en: jing_an_residence_en,
  },
  jingAn: {
    zh: '静安',
    tzh: '靜安',
    en: 'Jing An',
  },
  kerry: {
    zh: '嘉里公寓',
    tzh: '嘉里公寓',
    en: 'Kerry',
  },
  residences: {
    zh: '',
    tzh: '',
    en: 'Residences',
  },
  description1: {
    zh: '静安嘉里公寓位于静安区南京西路，上海最负盛名的繁华商业地带，建筑风格及室内装潢温馨舒适、精致优雅。',
    tzh: '靜安嘉里公寓位於靜安區南京西路，上海最負盛名的繁華商業地帶，建築風格及室內裝潢溫馨舒適、精致優雅。',
    en: `Sophisticated elegance awaits you at Jing An Kerry Residences, located just off West Nanjing Road, in Shanghai's cosmopolitan Central Business District.`,
  },
  description2: {
    zh: '静安嘉里公寓总建筑面积约18,000平方米，包含133套不同规格的公寓。每套都配备有齐全的厨用设备，宽敞的客厅、厨房空间，以及独立的卧室空间，功能性极佳。高效先进的安保设备和24小时安保服务，保障您和您的家人安全无忧。不论您在上海是商务差旅还是休闲入住，静安嘉里公寓都能让您感到家一般的舒适和惬意。',
    tzh: '靜安嘉里公寓總建築面積約18,000平方米，包含133套不同規格的公寓。每套都配備有齊全的廚用設備，寬敞的客廳、廚房空間，以及獨立的臥室空間，功能性極佳。高效先進的安保設備和24小時安保服務，保障您和您的家人安全無憂。不論您在上海是商務差旅還是休閑入住，靜安嘉里公寓都能讓您感到家一般的舒適和愜意。',
    en: 'With a GFA of 18,000 sqm, Jing An Kerry Residences house 133 apartments, each of which features a fully-equipped kitchen, spacious living and dining areas setting apart from the bedroom with state-of-the-art home entertainment systems. Efficient business support services ensure business executives are well-supported, while the 24-hour security system with closed circuit TV provides a safe living environment for you and your family. Whether you are in Shanghai for business or pleasure, Jing An Kerry Residences will make you feel right at home.',
  },
  buttonText: {
    zh: '联系我们',
    tzh: '聯繫我們',
    en: 'reach out',
  }
}
export const hotelContent = {
  kerry: {
    zh: '静安嘉里中心',
    tzh: '靜安嘉里中心',
    en: 'Kerry',
  },
  residences: {
    zh: ' 服务式公寓',
    tzh: '服務式公寓',
    en: 'Residences',
  },
  buttonText: {
    zh: '了解更多',
    tzh: '了解更多',
    en: 'find out more',
  },
  shangriLa:{
    zh: '静安香格里拉酒店',
    tzh: '靜安香格里拉酒店',
    en: 'Shangri-La',
  },
  shangriLaPng:{
    zh: jing_an_hotel_zh,
    tzh: jing_an_hotel_tzh,
    en: jing_an_hotel_en,
  }
}
export const addressContent={
  lets: {
    zh: '',
    tzh: '',
    en: 'let’s',
  },
  meet:{
    zh: '如何',
    tzh: '如何',
    en: 'meet',
  },
  here:{
    zh:'到达',
    tzh:'到達',
    en:'here'
  },
  address:{
    zh:'位置',
    tzh:'位置',
    en:'location'
  },
  addressNo:{
    zh:'上海市静安区南京西路1515号',
    tzh:'上海市靜安區南京西路1515號',
    en:'No. 1515, West NanJing Road, JingAn District, Shanghai, 200040'
  },
  openingHours:{
    zh:'营业时间',
    tzh:'營業時間',
    en:'opening hours'
  },
  getThere:{
    zh:'到达路线',
    tzh:'到達路線',
    en:'get there'
  },
  subWey:{
    zh:'地铁',
    tzh:'地鐵',
    en:'ShangHai Metro'
  },
  carPark:{
    zh:'停车',
    tzh:'停車',
    en:'Car Park'
  },
  followUs:{
    zh:'关注我们',
    tzh:'關注我們',
    en:'connect'
  },
  drivingRoute:{
    zh:'驾驶路线',
    tzh:'行車路線',
    en:'Driving Route'
  },
  meetHere:{
    zh:meet_here_zh,
    tzh:meet_here_tzh,
    en:meet_here_en
  }
}


