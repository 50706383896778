import about1 from "./images/about-1.png";
import logo1 from "./images/logo1.png";
import typeface from "./images/typeface.png"
import splitters from "./images/splitters.png";
import about from './images/about.png';
import address from './images/address.png';
import contact from './images/contact.png';
import hotel from './images/hotel.png';
import jakcmall from './images/jakcmall.png';
import office from './images/office.png';
import residence from './images/residence.png';
import home from './images/home.png';
import about_banner_1 from "./images/about-banner-1.jpg";
import about_banner_2 from "./images/about-banner-2.jpeg";
import about_banner_3 from "./images/about-banner-3.jpg";
import path from "./images/path.png";
import arrow_left from "./images/arrow-left.png";
import arrow_right from "./images/arrow-right.png";
import meet_our_brand from "./images/meet.png";
import brand13 from "./images/brand13.png";
import brand21 from "./images/brand21.png";
import brand22 from "./images/brand22.png";
import brand23 from "./images/brand23.png";
import hotel_1 from "./images/hotel-1.png";
import residences_1 from "./images/residences.png";
import car from "./images/car.png";
import subway from "./images/subway.png";
import xiaohongshu from "./images/xiaohongshu.png";
import lock from "./images/lock.png";
import weixin from "./images/weixin.png";
import bottom_icon from "./images/bottom_icon.png";
import yellow_icon from "./images/yellow-arrow.png";
import arrow_down from "./images/arrow-down.png";
import residence_banner1 from "./images/residence-banner1.jpg"
import residence_banner2 from "./images/residence-banner2.jpg"
import residence_banner3 from "./images/residence-banner3.jpg"
import jing_an_residence_zh from "./images/jing-an-residence-zh.png"
import jing_an_residence_tzh from "./images/jing-an-residence-tzh.png"
import jing_an_residence_en from "./images/jing-an-residence-en.png";
import ask_us_en from "./images/ask-us-en.png";
import ask_us_tzh from "./images/ask-us-tzh.png";
import ask_us_zh from "./images/ask-us-zh.png";
const image = {
  about1,
  logo1,
  typeface,
  splitters,
  about,
  address,
  contact,
  hotel,
  jakcmall,
  office,
  residence,
  home,
  about_banner_1,
  about_banner_2,
  about_banner_3,
  path,
  arrow_left,
  arrow_right,
  meet_our_brand,
  brand13,
  brand21,
  brand22,
  brand23,
  hotel_1,
  residences_1,
  car,
  subway,
  xiaohongshu,
  lock,
  weixin,
  bottom_icon,
  yellow_icon,
  arrow_down,
  residence_banner1,
  residence_banner2,
  residence_banner3,
  jing_an_residence_zh,
  jing_an_residence_tzh,
  jing_an_residence_en,
  ask_us_en,
  ask_us_tzh,
  ask_us_zh,
  
}

export default image