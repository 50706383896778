import React, { useEffect, useState } from "react";
import image from "./image";
import './about.scss';
import axios from 'axios';
import SubMenu from "../menu/menu";
import Banner from "./banner/banner";
import JakcMall from "./jakcMall/jakcMall";
import Office from "./office/office";
import Hotel from "./hotel/hotel"
import Residence from "./residence/residence";
import Address from "./address/address";
import Header from "../header";
import { aboutContent } from "../../../copywriting";
import null1 from './images/1.jpeg';
const About = () => {
  const [show, setShow] = useState(false);
  const [scrollTop, setScrollTop] = useState(0);
  const [currentTab, setCurrentTab] = useState('about');
  const [logoHeight, setLogoHeight] = useState(0);
  const [lang, setLang] = useState('zh');

  const [aboutBanner, setAboutBanner] = useState([]);
  const [officeBanner, setOfficeBanner] = useState([]);
  const [residenceBanner, setResidenceBanner] = useState([]); // 暂无后台维护

  useEffect(() => {
    if (sessionStorage.getItem('lang')) {
      console.log(sessionStorage.getItem('lang'))
      setLang(sessionStorage.getItem('lang'));
    }

    setShow(true);
    setTimeout(() => {
      getLogoHeight()
    }, 1)
    let scroolt_box = document.getElementById('scroll-box')
    scroolt_box.addEventListener('scroll', handleScroll);
    // window.addEventListener('wheel', handleWheel);
    return () => {
      scroolt_box.removeEventListener('scroll', handleScroll);
      // window.removeEventListener('wheel', handleWheel);
    }
  }, [])

  useEffect(() => {
    getAboutImgs();
    getOfficeImgs();
  }, []);

  function getAboutImgs() {
    axios.get(`https://qa-jakc-www.kerryplus.com/jeecg-boot/imgSetting/imgSetting/list?useType=introduce`).then( res => {
      // console.log(res.data)
      if (res.data.success) {
        let data = res.data.result ? res.data.result.records : [];
        setAboutBanner(data);
      }
    }).catch( err => {
      // console.log(err)
    })
  }

  function getOfficeImgs() {
    axios.get(`https://qa-jakc-www.kerryplus.com/jeecg-boot/imgSetting/imgSetting/list?useType=office`).then( res => {
      // console.log(res.data)
      if (res.data.success) {
        let data = res.data.result ? res.data.result.records : [];
        setOfficeBanner(data);
      }
    }).catch( err => {
      // console.log(err)
    })
  }

  function getResidenceImgs() {
    axios.get(`https://qa-jakc-www.kerryplus.com/jeecg-boot/imgSetting/imgSetting/list?useType=office`).then( res => {
      // console.log(res.data)
      if (res.data.success) {
        let data = res.data.result ? res.data.result.records : [];
        setResidenceBanner(data);
      }
    }).catch( err => {
      // console.log(err)
    })
  }

  const getLogoHeight = () => {
    let dom = document.getElementById('top-logo');
    let height = 0;
    if (dom) {
      height = dom.clientHeight;
    }
    setLogoHeight(height)
  }

  const handleScroll = () => {
    let scrollTop = document.getElementById('scroll-box').scrollTop;
    let clientHeight = document.body.clientHeight;
    let box1 = document.getElementById('about');
    let box2 = document.getElementById('jakcMall');
    let box3 = document.getElementById('office');
    let box4 = document.getElementById('residence');
    let box5 = document.getElementById('hotel');
    let box6 = document.getElementById('address');
    let h = scrollTop;
    // console.log(h)
    if (h <= 0) {
      // window.location.href = '/index';
    }

    /* if (h < 200) {
      setFlag1(false)
    }
    if (h >= 200 && h < 600) {
      setFlag1(true)
    }

    if (h < 800) {
      setFlag2(false)
    }
    if (h >= 800 && h < 1400) {
      setFlag2(true)
    } */

    if (scrollTop < box1.offsetTop || (scrollTop > box1.offsetTop && scrollTop < (box1.clientHeight - 100))) {
      setCurrentTab('about')
    } else if (h > (box2.offsetTop - 200) && h <= (box2.offsetTop + box2.clientHeight - 200)) {
      setCurrentTab('jakcMall')
    } else if ((h > box3.offsetTop - 200) && h <= (box3.offsetTop + box3.clientHeight - 200)) {
      setCurrentTab('office')
    } else if ((h > box4.offsetTop - 200) && h <= (box4.offsetTop + box4.clientHeight - 200)) {
      setCurrentTab('residence')
    } else if (h > (box5.offsetTop - 200) && h <= (box5.offsetTop + box5.clientHeight - 200)) {
      setCurrentTab('hotel')
    } else if (h > (box6.offsetTop - 200)) {
      setCurrentTab('address')
    }
    setScrollTop(scrollTop);
  }
  const handleWheel = (event) => {
    let scrollTop = document.documentElement.scrollTop || document.body.scrollTop
    // 检查滚轮事件的deltaY属性
    // console.log(event.deltaY, scrollTop)
    if (event.deltaY < 0 && scrollTop <= 0) {
      window.location.href = '/index';
    }
  }
  const onTabChange = (index) => {
    const dom = document.getElementById(index);
    if (dom) {
      dom.scrollIntoView({
        behavior: 'smooth',
        block: 'start'
      })
      setCurrentTab(index)
    }
  }
  const hiddenMenu = () => {
    let banner1 = document.getElementById('about-banner1');
    let banner2 = document.getElementById('about-banner2');
    let banner3 = document.getElementById('about-banner3');
    if ((banner1 && (scrollTop > banner1.offsetTop - 300) && (scrollTop < banner1.offsetTop + banner1.clientHeight - 300)) || (banner2 && (scrollTop > banner2.offsetTop - 300) && (scrollTop < banner2.offsetTop + banner2.clientHeight - 300)) || (banner3 && (scrollTop > banner3.offsetTop - 300) && (scrollTop < banner3.offsetTop + banner3.clientHeight - 300))) {
      return true;
    }
    return false;
  }
  const hiddenTop = () => {
    let box1 = document.getElementById('about');
    if (box1 && (scrollTop + 10 > box1.offsetTop + box1.clientHeight)) {
      return true;
    }
    return false
  }

  function getLang(lang) {
    // console.log(lang, '--设置')
    setLang(lang);
  }

  const toAdress = () => {
    // onTabChange('address');
    window.location.href = '/contact';
  }
  const toNextPage = () => {
    onTabChange('about-banner1');
  }

  return (
    <>
      <div className={show ? 'about-page page1' : 'about-page'} id='about-page' >
        <div className="out-box">
          <SubMenu onChange={onTabChange} currentTab={currentTab} hiddenMenu={hiddenMenu} subMenuShow={true}
            activeColor={'#003593'}
            defaultColor={'#000000'}
            lang={lang}
          />
          <div className={show ? "about-animation" : ''}  id='scroll-box'>
            <div className="about-container scroll-item" id='about'>
              <div className="about-header">
                <div style={{ height: logoHeight, display: hiddenTop() ? 'none' : '', }}>
                  <img className="about-1" src={image.about1} alt="" />
                  <Header onTabChange={onTabChange} currentTab={currentTab}
                    hiddenMenu={hiddenMenu} subMenuShow={true}
                    getLang={getLang}
                  />
                </div>
              </div>
              <img src={image.splitters} alt="" className="splitters" />
              <div className="about-main">
                <div className="about-context" style={{ display: hiddenTop() ? 'none' : '' }}>
                  <div className="context">
                    <div className="context-header">
                      <span style={{visibility: "hidden"}}>jing an kerry centre </span>
                      <span className="context-header-connecting-line"
                        style={{visibility: "hidden"}}
                      ></span>
                      <span style={{visibility: "hidden"}}> centre · core · creativity · connection</span>
                    </div>
                    <div className="describe">
                      <div className="context-left">
                        <div className="title">
                          <img src={aboutContent.jingAnCenter[lang]} alt="" />
                        </div>
                      </div>
                      <div className="context-right">
                        <img src={null1} className="nullimg" />
                        <div className="text-one">{aboutContent.description1[lang]}</div>
                        <div className="text-two">
                          {aboutContent.description2[lang]}
                        </div>
                        <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                          <div className="page-btn" onClick={toAdress}>
                            {aboutContent.buttonText[lang]} <img src={image.path} />
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>

                  <div className="arrow-down" onClick={toNextPage}>
                    <img src={image.arrow_down} alt="" />
                  </div>
                </div>
              </div>
            </div>
            <Banner currentTab={currentTab} id='about-banner1' lang={lang} imageList={aboutBanner} />
            <JakcMall currentTab={currentTab} scrollTop={scrollTop} lang={lang} />
            <Office currentTab={currentTab} lang={lang} onTabChange={onTabChange} />
            <Banner currentTab={currentTab} id='about-banner2' lang={lang} imageList={officeBanner} />
            <Residence currentTab={currentTab} lang={lang} onTabChange={onTabChange} />
            <Banner currentTab={currentTab} id='about-banner3' lang={lang} imageList={[image.residence_banner1, image.residence_banner3]} />
            <Hotel currentTab={currentTab} lang={lang} />
            <Address currentTab={currentTab} lang={lang} />
          </div>
        </div>
      </div>
    </>
  );
};

export default About;
