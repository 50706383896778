import React, { useEffect, useState } from "react";
// import HeaderOne from "../components/HeaderOne";
// import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation, Pagination, Scrollbar, Autoplay, EffectFade, } from 'swiper';
// install Swiper modules
// import FooterAreaOne from "../../components/FooterAreaOne";
/* import Breadcrumb from "../components/Breadcrumb";
import SubscribeOne from "../components/SubscribeOne";
import ContactArea from "../components/ContactArea"; */
import './index.scss';
import axios from 'axios';
import Preloader from "../../helper/Preloader";

import Banner1 from './images/index/banner1.jpeg';
import Banner2 from './images/index/banner2.jpeg';
import Banner3 from './images/index/banner3.jpeg';
import Banner4 from './images/index/banner4.jpeg';
import Banner5 from './images/index/banner5.jpeg';
import Banner6 from './images/index/banner6.jpeg';

import ba_r1 from './images/index/ba_r1.png';
import ba_r2 from './images/index/ba_r2.png';
import ba_r3 from './images/index/ba_r3.png';
import ba_r4 from './images/index/ba_r4.png';
import ba_r5 from './images/index/ba_r5.png';
import ba_r6 from './images/index/ba_r6.png';

import arrow_left from "./images/index/arrow-left.png"
import arrow_right from "./images/index/arrow-right.png"
import arrow_down from "./images/index/arrow-down.png"

// import sensors from '../../sensorsdata.es6.min.js';
import "animate.css";
import ReactCSSTransitionGroup from "react-addons-css-transition-group";

import Header from '../component/header'
import SubMenu from "../component/menu/menu.jsx";
import Footer from "../component/footer";
SwiperCore.use([Navigation, Pagination, Scrollbar]);

const ContactPage = () => {
  let [active, setActive] = useState(true);
  const [list, setList] = useState([]);
  const [lang, setLang] = useState('zh');

  const rList = [ba_r1, ba_r2, ba_r3, ba_r4, ba_r5, ba_r6];

  useEffect(() => {
    if (sessionStorage.getItem('lang')) {
      setLang(sessionStorage.getItem('lang'));
    }
    // console.log(sensors)
    getImages();

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll)

  }, []);

  const handleScroll = () => {
    let scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
    // console.log(scrollTop)
    if (scrollTop > 0) {
      window.location.href = '/about';
    }
  }

  function getImages() {
    axios.get(`https://qa-jakc-www.kerryplus.com/jeecg-boot/imgSetting/imgSetting/list?useType=index`).then( res => {
      // console.log(res.data)
      if (res.data.success) {
        let data = res.data.result ? res.data.result.records : [];
        setList(data);
        setActive(false);
      }
    }).catch( err => {
      // console.log(err)
      setActive(false);
    })
  }

  function getLang(lang) {
    console.log(lang, '--设置')
    setLang(lang);
  }

  return (
    <div id="index-page">
      {active && <Preloader />}
      
      <Header getLang={getLang} />

      <SubMenu 
        onChange={() => { }} 
        currentTab={'home'} 
        hiddenMenu={() => { }} 
        subMenuShow={false}
        activeColor={'#F3EA00'}
        defaultColor={'#FFFFFF'}
        lang={lang}
      />
      
      {list.length > 0 && <Swiper
        spaceBetween={50}
        // navigation
        pagination
        scrollbar
        loop={true}
        modules={[Autoplay, Navigation, EffectFade]}
        autoplay={{ delay: 5000, disableOnInteraction: false }}
        style={{ width: '100%', height: '100vh' }}
        effect="fade"
        navigation={{
          nextEl: ".arrow-right",
          prevEl: ".arrow-left",
        }}
        observer={true}
      >
         <img src={arrow_left} alt="" className="arrow-left" />
         <img src={arrow_right} alt="" className="arrow-right" />
        {list.map((v, i) => {
          return <SwiperSlide style={{ position: 'relative' }} key={i}>
            {/* <ReactCSSTransitionGroup
              transitionEnter={true}
              transitionLeave={true}
              transitionEnterTimeout={500}
              transitionLeaveTimeout={500}
              transitionName="animated"
            >
              <img src={rList[i]} className='ba_r_img animate__animated animate__fadeInRight' />
            </ReactCSSTransitionGroup> */}
            <img src={rList[i]} className='ba_r_img ' />
            <img src={v.imgUrl} alt="banner"
              style={{ width: '100%', height: '100%' }}
              className="swiper-img"
            />
          </SwiperSlide>
        })}

      </Swiper>}

      <div style={{borderBottom: '1px solid #fff'}}></div>

      {/* <ReactCSSTransitionGroup
        transitionEnter={true}
        transitionLeave={true}
        transitionEnterTimeout={2500}
        transitionLeaveTimeout={1500}
        transitionName="animated"
      >
        <div key="amache" className="arrow_down animate__animated animate__shakeY">
          <img src={arrow_down} style={{width: 30, height: 30}} />
        </div>
      </ReactCSSTransitionGroup> */}
      <div className="arrow_down animate__animated animate__shakeY">
        <img src={arrow_down} style={{width: 30, height: 30}} />
      </div>
      

      <Footer 
        style={{ background: '#F3EA00' }} 
        lang={lang}
      />
      {/* Header one */}
      {/* <HeaderOne /> */}

      {/* Breadcrumb */}
      {/* <Breadcrumb title={"Contact"} /> */}

      {/* Contact Area */}
      {/* <ContactArea /> */}

      {/* Subscribe One */}
      {/* <SubscribeOne /> */}

      {/* Footer Area One */}
      {/* <FooterAreaOne /> */}
    </div>
  );
};

export default ContactPage;
