import React, { useEffect, useState } from "react";
import './menuDialog.scss';
import close_icon from "./images/close.png";
import down_icon from "./images/down.png";
import up_icon from "./images/up.png";
import mobile_menu from "./images/mobile-menu.png"
import { Link } from "react-router-dom";
import { menuContent } from "../../../copywriting"
const activeColor = '#003593';
const defaultColor = '#000000';
const MenuDialog = ({ onClose, currentTab, onLanguageChange, language, subMenuShow, onSubTabChange }) => {
  const [langOpen, setLangOpen] = useState(false);
  const [aboutOpen, setAboutOpen] = useState(false);
  const [menuLayerShow, setMenuLayerShow] = useState(false);
  useEffect(() => {
    setAboutOpen(subMenuShow ? true : false)
  }, [])
  const onTabChange = (e, key) => {
    setMenuLayerShow(false)
    e.stopPropagation();
    onSubTabChange(key);
  }
  const onLinkClick = () => {
    onClose()
    console.log('---')
  }
  const onAboutClick = () => {
    if (!subMenuShow) {
      onClose()
    } else {
      setAboutOpen(!aboutOpen)
    }
  }
  return (
    <>
      <img src={mobile_menu} alt="" className="mobile-menu-icon" onClick={() => setMenuLayerShow(true)} />
      <div className="menu-pop-up-layer" style={{ display: !menuLayerShow ? 'none' : 'block' }}>
        <div>
          <img src={close_icon} alt="" className="close-icon" onClick={() => setMenuLayerShow(false)} />
          <div className="menu-pop-main">
            <div className={`menu-title-1  ${currentTab === 'home' ? 'menu-active' : ''}`}>
              <Link to="/index" style={{ color: currentTab === 'home' ? activeColor : defaultColor }}><div onClick={() => onLinkClick()}>{menuContent.home[language]}</div> </Link>
            </div>
            <div>
              <div className={`menu-title-1 menu-flex  ${aboutOpen ? 'menu-active' : ''}`} onClick={() => onAboutClick()}>
                <Link to="/about" style={{ width: '80%', color: currentTab === 'about' ? activeColor : defaultColor }}>{menuContent.about[language]}</Link>
                <img src={aboutOpen ? up_icon : down_icon} alt="" className="menu-icon" />
              </div>
              <div className="sub-item" style={{ display: aboutOpen ? '' : 'none' }}>
                <div className={`menu-title-2 ${currentTab === 'jakcMall' ? 'menu-active' : ''}`} onClick={(e) => onTabChange(e, 'jakcMall')}>{menuContent.jakcMall[language]}</div>
                <div className={`menu-title-2 ${currentTab === 'office' ? 'menu-active' : ''}`} onClick={(e) => onTabChange(e, 'office')}>{menuContent.office[language]}</div>
                <div className={`menu-title-2 ${currentTab === 'residence' ? 'menu-active' : ''}`} onClick={(e) => onTabChange(e, 'residence')}>{menuContent.residence[language]}</div>
                <div className={`menu-title-2 ${currentTab === 'hotel' ? 'menu-active' : ''}`} onClick={(e) => onTabChange(e, 'hotel')}>{menuContent.hotel[language]}</div>
                <div className={`menu-title-2 ${currentTab === 'address' ? 'menu-active' : ''}`} onClick={(e) => onTabChange(e, 'address')}>{menuContent.address[language]}</div>
              </div>
            </div>
            <div className={`menu-title-1 ${currentTab === 'contact' ? 'menu-active' : ''}`}>
              <Link to="/contact" style={{color: currentTab === 'contact' ? activeColor : defaultColor }}><div>{menuContent.contact[language]}</div></Link>
            </div>
            <div>
              <div className={`menu-title-1 menu-flex  ${langOpen ? 'menu-active' : ''}`} onClick={() => setLangOpen(!langOpen)}>
                {menuContent.language[language]}
                <img src={langOpen ? up_icon : down_icon} alt="" className="menu-icon" />
              </div>
              <div className="sub-item" style={{ display: langOpen ? '' : 'none' }}>
                <div className={`menu-title-2 ${language === 'zh' ? 'menu-active' : ''}`} onClick={() => onLanguageChange('zh')}>简</div>
                <div className={`menu-title-2 ${language === 'tzh' ? 'menu-active' : ''}`} onClick={() => onLanguageChange('tzh')}>繁</div>
                <div className={`menu-title-2 ${language === 'en' ? 'menu-active' : ''}`} onClick={() => onLanguageChange('en')}>EN</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MenuDialog;
