import { BrowserRouter, Route, Routes } from "react-router-dom";
import RouteScrollToTop from "./helper/RouteScrollToTop";
import ScrollToTop from "react-scroll-to-top";

// 静安 嘉里中心内容
import Index from './pages/Kerry/index';
import About from "./pages/component/about/about";
import Contact from "./pages/component/contact/contact";
import Privacy from "./pages/Kerry/privacy";

// console.log('process.env.NODE_ENV', process.env.NODE_ENV);
// let sensorUrl = 'https://t.kerryplus.com/sa.gif?project=jakc_web';
let sensorUrl = 'https://tq.kerryprops.com.cn/sa.gif?project=jakc_web';
if (process.env.NODE_ENV == 'development') {
  sensorUrl = 'https://tq.kerryprops.com.cn/sa.gif?project=jakc_web';
}

// 埋点
var sensors = require('sa-sdk-javascript'); 
sensors.init({
  server_url: sensorUrl,
  // is_track_single_page: true, // 单页面配置，默认开启，若页面中有锚点设计，需要将该配置删除，否则触发锚点会多触发 $pageview 事件
  use_client_time: true, 
  send_type: 'beacon',
  show_log: true,
  heatmap: {
     //是否开启点击图，default 表示开启，自动采集 $WebClick 事件，可以设置 'not_collect' 表示关闭。
     clickmap: 'default',
     //是否开启触达图，not_collect 表示关闭，不会自动采集 $WebStay 事件，可以设置 'default' 表示开启。
     scroll_notice_map: 'default'
  }
});
sensors.quick('autoTrack'); //用于采集 $pageview 事件。

function App() {
  return (
    <BrowserRouter>
      <RouteScrollToTop />
      <ScrollToTop smooth color="#E8092E" />
      <Routes>
        <Route exact path="/" element={<Index />} />
        <Route exact path="/index" element={<Index />} />
        <Route exact path="/about" element={<About />} />
        <Route exact path="/contact" element={<Contact />} />
        <Route exact path="/privacy" element={<Privacy />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
