import React, { useEffect, useState } from "react";
import images from "../about/image";
import './menu.scss';
import { Link } from "react-router-dom";
import TextCon from "../../../textContent";

const SubMenu = ({ onChange, currentTab, hiddenMenu, subMenuShow, activeColor, defaultColor, lang }) => {

  const onTabChange = (e, key) => {
    e.stopPropagation();
    onChange(key);
  }

  return (
    <>
      <div className={`container ${hiddenMenu() ? 'menu-hidden' : 'menu-show'}`}>
        <div className={"menu-container"}>
          <ul>
            <li className="menu-1-style" 
              // style={currentTab === 'home' ? { color: activeColor } : {}} 
              style={{ color: currentTab === 'home' ? activeColor : defaultColor, fontSize: lang === 'en' ? '35px' : '32px' }}
              onClick={(e) => onTabChange(e, 'home')}
            >
              <Link to="/index" style={{ color: currentTab === 'home' ? activeColor : defaultColor }}>
                {TextCon['home'][lang]}
              </Link>
            </li>
            <li className="menu-1-style" onClick={(e) => subMenuShow ? onTabChange(e, 'about') : {}}>
              <span 
                style={{ color: currentTab === 'about' ? activeColor : defaultColor, fontSize: lang === 'en' ? '35px' : '32px' }}
              >
              <Link to="/about" style={{ color: currentTab === 'about' ? activeColor : defaultColor }}> 
                {TextCon['about'][lang]}
              </Link>
            </span>
              <div className="sub-menu" style={subMenuShow ? { display: 'block' } : { display: 'none' }}>
                <li className="menu-2-style" style={currentTab === 'jakcMall' ? { color: activeColor } : {}} onClick={(e) => onTabChange(e, 'jakcMall')}>
                  {TextCon['jakcmall'][lang]}
                </li>
                <li className="menu-2-style" style={currentTab === 'office' ? { color: activeColor } : {}} onClick={(e) => onTabChange(e, 'office')}>
                  {TextCon['office'][lang]}
                </li>
                <li className="menu-2-style" style={currentTab === 'residence' ? { color: activeColor } : {}} onClick={(e) => onTabChange(e, 'residence')}>
                  {TextCon['residence'][lang]}
                </li>
                <li className="menu-2-style" style={currentTab === 'hotel' ? { color: activeColor } : {}} onClick={(e) => onTabChange(e, 'hotel')}>
                  {TextCon['hotel'][lang]}
                </li>
                <li className="menu-2-style" style={currentTab === 'address' ? { color: activeColor } : {}} onClick={(e) => onTabChange(e, 'address')}>
                  {TextCon['address'][lang]}
                </li>
              </div>
            </li>
            <li className="menu-1-style" 
              style={{ color: currentTab === 'contact' ? activeColor : defaultColor, fontSize: lang === 'en' ? '35px' : '32px'  }}
            >
              <Link to="/contact" style={{ color: currentTab === 'contact' ? activeColor : defaultColor }}>
                {TextCon['contact'][lang]}
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default SubMenu;
