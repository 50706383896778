const textCon = {
    // menu
    home: {
        zh: '主页',
        en: 'home',
        tzh: '主頁',
    },
    about: {
        zh: '关于我们',
        en: 'about',
        tzh: '關於我們',
    },
    contact: {
        zh: '联系我们',
        en: 'contact',
        tzh: '聯繫我們',
    },
    
    jakcmall: {
        zh: 'JAKC 商场',
        en: 'JAKC mall',
        tzh: 'JAKC 商場',
    },
    office: {
        zh: '办公',
        en: 'office',
        tzh: '辦公',
    },
    residence: {
        zh: '公寓',
        en: 'residence',
        tzh: '公寓',
    },
    hotel: {
        zh: '酒店',
        en: 'hotel',
        tzh: '酒店',
    },
    address: {
        zh: '位置',
        en: 'location',
        tzh: '位置',
    },


    // footer
    footerAdd: {
        zh: '© 2024 静安嘉里中心',
        en: '© 2024 Jing An Kerry Centre',
        tzh: '© 2024 靜安嘉里中心',
    },
    footerPri: {
        zh: '隐私政策',
        en: 'Privacy Policy',
        tzh: '隱私政策',
    },
    footerR1: {
        zh: '沪ICP备 19003416号-3',
        en: '沪ICP备 19003416号-3',
        tzh: '沪ICP备 19003416号-3',
    },
    footerR2: {
        zh: '沪公网安备 31010602006468号',
        en: '沪公网安备 31010602006468号',
        tzh: '沪公网安备 31010602006468号',
    },
    
};

export default textCon;