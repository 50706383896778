import React, { useEffect, useState } from "react";
import image from "../image";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, FreeMode, Thumbs, EffectFade, Autoplay } from "swiper";
import meet from '../images/banner1-m.png';
import jakc from '../images/banner1-j.png';
import "animate.css";
import ReactCSSTransitionGroup from "react-addons-css-transition-group";

import "./banner.scss"

const Banner = ({ id, imageList }) => {
  return (
    <>
      <div id={id || ''}>
        <div className="about-banner scroll-item">
          {imageList && imageList.length > 0 && <Swiper
            loop={true}
            modules={[Autoplay, FreeMode, EffectFade, Thumbs, Navigation]}
            autoplay={{ delay: 5000, disableOnInteraction: false }}
            pagination
            // effect="fade"
            navigation={{
              nextEl: ".arrow-right",
              prevEl: ".arrow-left",
            }}
            style={{ width: '100%', height: '100vh', position: 'relative' }}
            observer={true}
          >
            <img src={image.arrow_left} alt="" className="arrow-left" />
            <img src={image.arrow_right} alt="" className="arrow-right" />
            {
              (imageList && imageList.length > 0) ? imageList.map((el, i) => {
                return <SwiperSlide key={i}>
                  {/* {id == 'about-banner1' && <ReactCSSTransitionGroup
                    transitionEnter={true}
                    transitionLeave={true}
                    transitionEnterTimeout={2000}
                    transitionLeaveTimeout={2000}
                    transitionName="animated"
                  >
                    <img src={meet} className='banner1-m animate__animated animate__zoomIn' />
                  </ReactCSSTransitionGroup>}
                  {id == 'about-banner1' && <ReactCSSTransitionGroup
                    transitionEnter={true}
                    transitionLeave={true}
                    transitionEnterTimeout={3000}
                    transitionLeaveTimeout={3000}
                    transitionName="animated"
                  >
                    <img src={jakc} className='banner1-j animate__animated animate__zoomIn' />
                  </ReactCSSTransitionGroup>} */}
                  <img src={el.imgUrl || el} alt="" className="swiper-img" style={{ width: '100%', height: '100%' }} />
                </SwiperSlide>
              })
                : ''
            }
          </Swiper>}
        </div>
      </div>
    </>
  );
};

export default Banner;
